.grid-container-in-books {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row: minmax(10rem, auto);
}

@media screen and (max-width: 768px) {
    .grid-container-in-books {
        grid-template-columns: repeat(1, 1fr);
    }
}