.book-item-container,
.book-item-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.3s ease-in-out;
  color: black;
  text-decoration: none;
  position: relative;
}

.book-item-container {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 30px;
  border-radius: 5px;
}

.book-item-container:hover {
  color: initial;
}

.book-item-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

/* .add-to-cart-btn {
    z-index: 2000;
} */

.book-item-image {
  width: 100%;
  max-height: 300px;
}

.book-item-title {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.book-item-price {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.price-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.book-item-old-price {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-decoration: line-through;
  color: #c2c2c2;
}

.book-item-price {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
