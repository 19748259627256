.order {
    cursor: pointer;
}

.text {
    user-select: none !important;
    cursor: pointer;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row: minmax(100px solid);
}

.grid-container>div {
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    position: relative;
    transition: 250ms;
    bottom: 0;
}

.grid-container>div:hover {
    background-color: #ccc;
}